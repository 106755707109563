import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Button.scss";

export const Button = ({ buttonText, buttonLink }) => {
  return (
    <div className="button">
      {buttonLink && (
        <GatsbyLink className="button-component" to={buttonLink}>
          {buttonText}
        </GatsbyLink>
      )}
    </div>
  );
};
