import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import { BackgroundImageAndText } from "../../components/Acf/BackgroundImageAndText";
import "../../sass/global/styles.scss";

const Layout = ({ children, location, wordpressUrl }) => {
  const [isPageWithContactContent, setIsPageWithContactContent] =
    useState(false);

  // pages that don't need an extra BackgroundImageAndText component
  const pages = [
    "/",
    "/contact/",
    "/technology-innovation/",
    "/about-us/careers/",
    "/capabilities/roadworks/",
    "/capabilities/earthworks/",
    "/capabilities/product-supply/",
    "/capabilities/cartage/",
  ];

  useEffect(() => {
    pages.includes(location.pathname)
      ? setIsPageWithContactContent(true)
      : setIsPageWithContactContent(false);
  }, [location]);

  return (
    <div id="layout">
      <Header location={location} />
      <div className="main">{children}</div>
      {!isPageWithContactContent && <BackgroundImageAndText />}
      <Footer location={location} />
    </div>
  );
};
export default Layout;
