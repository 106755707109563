import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Button } from "../../Button";
import "./BackgroundImageAndText.scss";

export const BackgroundImageAndText = ({
  content,
  imagePath,
  cta,
  extraHorizontalPadding,
  leftAlignedText,
}) => {
  return (
    <section
      className={`background-image-and-text--component${
        extraHorizontalPadding ? "" : " vertical-padding"
      }`}
    >
      <div className="background-helper">
        <div className="image-wrapper">
          <ImagePass src={imagePath} className="image-img" />
        </div>
      </div>
      <div className={`inner${leftAlignedText ? "" : " right-aligned-text"}`}>
        <div className="left-side">
          <RenderContent className="content" content={content}></RenderContent>
          <div className="cta-button">
            {cta ? (
              <Button buttonLink={cta?.url} buttonText={cta?.title} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="right-side"></div>
      </div>
    </section>
  );
};

BackgroundImageAndText.defaultProps = {
  content:
    "<span><h2>Contact Us</h2><h3>Get in touch today</h3><p>If you are looking for a professional, agile and attentive team to deliver all your needs – whether it is a small or large scale project – we would love to receive your invitation to tender.</p></span> ",

  imagePath: "/images/Mask-Group-63.png",

  cta: {
    url: "/contact/",
    title: "Contact us",
  },
  extraHorizontalPadding: true,
  leftAlignedText: true,
};
